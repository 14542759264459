<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="mb-3">
          <h3>
            โปรโมชั่น
          </h3>

        </div>
      </div>

    </div>
  </div>

</template>
<script>
export default {
  name: 'Promotion',
  components: {},
  data () {
    return {
    }
  },
  created () {}
}
</script>
